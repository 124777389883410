// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: 'https://sys.bondsclub.com.br/api/mobile.php?action=',
  applicationApiUrl: 'https://admin.appsbonds.club/api',
  applicationApiId: '4',
  partnerId: 776630,
  onesignalAppId: '8cca37ec-2ac3-4027-8c31-d81683cb10dd',
  onesignalFirebaseId: '1007764664499'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
