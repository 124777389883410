import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  public application: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  constructor(
    private _http: HttpClient,
  ) { 
    this._http.get<any>(`${environment.applicationApiUrl}/app/${environment.applicationApiId}`).subscribe(resp => {
      this.setApplication(resp.app);
    });
  }

  getApplication(): Observable<any> {
    return this.application.asObservable();
  }

  setApplication(application: any) {
    this.application.next(application);
  }

}
