import { Component, OnInit, OnDestroy } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ApplicationService } from './services/application.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { OneSignal } from '@ionic-native/onesignal/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public application: any;

  private unsubscribe$: Subject<boolean>;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private __appService: ApplicationService,
    private oneSignal: OneSignal,
  ) {
    this.initializeApp();
    this.initializeNotifications();
    this.unsubscribe$ = new Subject();
  }

  async initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  initializeNotifications() {
    this.platform.ready().then(() => {
      this.oneSignal.startInit(environment.onesignalAppId, environment.onesignalFirebaseId);
      this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);

      this.oneSignal.handleNotificationReceived().subscribe(() => {
      });

      this.oneSignal.handleNotificationOpened().subscribe(() => {
      });

      this.oneSignal.endInit();
    });
  }

  ngOnInit() {
    this.__appService
      .getApplication()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(application => this.application = application);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
